import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

Vue.use(VueRouter)

// 解决同一页面产生导航冗余，vue-router报错问题
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// };

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const scrollBehavior = (to, from, savedPosition) => {
  // console.log(savedPosition);
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
  // return { x: 0, y: 0 }; ////不同页面之间跳转，滚动到顶部，（解决滚定位置互相影响问题）
}

let routerConfig = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes
}

const ua = navigator.userAgent;
const isMobile = function () {
  let flag = ua.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|MicroMessenger)/i
  );
  return flag;
}
const terminal = isMobile() ? "mobile" : "pc"
const isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;

if (terminal == 'pc') {
  routerConfig.scrollBehavior = scrollBehavior
}


const router = new VueRouter(routerConfig)

router.beforeEach((to, from, next) => {
  if (terminal == 'mobile' && !isAndroid) {
    to.meta.keepAlive = true
  } else if (terminal == 'pc') {
    let homeRelod = sessionStorage.getItem("homeRelod") || ""
    if (to.name == "Artist") {
      to.meta.keepAlive = true
    } else if (to.name == "home" || to.name == "Home") {
      if (!homeRelod) {
        to.meta.keepAlive = true
      } else {
        to.meta.keepAlive = false;
      }
    } else {
      to.meta.keepAlive = false;
    }
  }

  if (to.meta && to.meta.title) {
    document.title = to.meta.title; // 设置页面标题
  }
  const protectedRoute = to.matched.some(route => route.meta.auth)
  // const adminRoute = to.matched.some(route => route.meta.admin)
  // const protectedRoute = to.meta?to.meta.auth:false
  if (protectedRoute) { // 判断跳转的路由是否需要登录
    if (localStorage.getItem("tokenLogin")) { // vuex.state判断token是否存在
      next() // 已登录
    } else {
      next({
        path: '/',
        // query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    return next()
  }
  // Allow guest routes
  if (!protectedRoute) return next()
  // login
  if (protectedRoute) return next()

  // If auth is required and the user is logged in, verify the token...
  if (app.store.getters['session/isAuthenticated']) {
    return app.store.dispatch('session/validate').then(user => {
      user ? next() : next({ name: 'user-login', query: { 'redirect': to.fullPath } })
    })
  }

  next({ name: 'user-login', query: { 'redirect': to.fullPath } })
})

export default router
